<template>
  <MainLayout
    bg-color="background-normal"
    no-content-padding
    aditional-classes="relative"
  >
    <template #toolbar>
      <div class="flex items-center justify-between px-5 py-3.5">
        <div class="flex items-center">
          <img
            src="../../assets/images/dashboard-icon.png"
            class="w-10 h-10 ring-1 ring-offset-1 rounded-lg ring-neutral-100 shadow-lg"
          >
          <div class="ml-3">
            <BaseText
              type="label"
              size="md"
              class="text-neutral-900 font-medium"
            >
              Dashboard
            </BaseText>

            <BaseText
              type="body"
              size="sm"
              class="text-text-normal w-52 overflow-visible whitespace-nowrap"
            >
              Your Creative Strategy Hub
            </BaseText>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="flex flex-col items-center justify-center mx-auto max-w-7xl">
        <!-- <BaseCardTransition>
          <BFCMCard />
        </BaseCardTransition> -->
        <BaseCardTransition>
          <AccountCard />
        </BaseCardTransition>
        <BaseCardTransition>
          <ReferralCard />
        </BaseCardTransition>
        <BaseCardTransition>
          <ExtentionCard />
        </BaseCardTransition>
        <!-- <BaseCardTransition>
          <CourseCard />
        </BaseCardTransition> -->
        <BaseCardTransition>
          <div class="w-4/5 flex gap-5">
            <BaseCardTransition>
              <TeamManagementCard />
            </BaseCardTransition>
            <BaseCardTransition>
              <TutorialCard />
            </BaseCardTransition>
          </div>
        </BaseCardTransition>
        <BaseCardTransition>
          <IntegrationsCard
            id="integrations-card"
            :team="team"
            @showModal="hideInstagramModal = false"
          />
        </BaseCardTransition>
        <BaseCardTransition>
          <SwipeFileCard />
        </BaseCardTransition>
        <BaseCardTransition>
          <DiscoveryCard />
        </BaseCardTransition>
        <BaseCardTransition>
          <BriefsCard />
        </BaseCardTransition>
        <BaseCardTransition>
          <BottomCards />
        </BaseCardTransition>
      </div>
      <InstagramModal
        v-if="!hideInstagramModal"
        @close="hideInstagramModal = true"
        @refresh="fetchTeam"
      />
    </template>
  </MainLayout>
</template>
<script>
import AccountCard from '../../components/dashboard/AccountCard.vue'
// import BFCMCard from '../../components/dashboard/BFCMCard.vue'
import BottomCards from '../../components/dashboard/BottomCards.vue'
import BriefsCard from '../../components/dashboard/BriefsCard.vue'
import ReferralCard from '../../components/dashboard/ReferralCard.vue'
// import CourseCard from '../../components/dashboard/CourseCard.vue'
import DiscoveryCard from '../../components/dashboard/DiscoveryCard.vue'
import ExtentionCard from '../../components/dashboard/ExtentionCard.vue'
import IntegrationsCard from '../../components/dashboard/IntegrationsCard.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import SwipeFileCard from '../../components/dashboard/SwipeFileCard.vue'
import TeamManagementCard from '../../components/dashboard/TeamManagmentCard.vue'
import TutorialCard from '../../components/dashboard/TutorialCard.vue'
import InstagramModal from '../../components/dashboard/Modals/InstagramModal.vue'
import { mapGetters } from 'vuex'
import FirebaseAPI from '@/api/firebase'

export default {
  name: 'DashboardView',
  components: {
    AccountCard,
    // BFCMCard,
    BottomCards,
    BriefsCard,
    // CourseCard,
    DiscoveryCard,
    ExtentionCard,
    IntegrationsCard,
    ReferralCard,
    MainLayout,
    SwipeFileCard,
    TeamManagementCard,
    TutorialCard,
    InstagramModal
  },
  data () {
    return {
      shoWrapped: false,
      hideInstagramModal: true,
      team: null
    }
  },
  metaInfo: {
    title: 'Foreplay.co | Dashboard'
  },
  computed: {
    ...mapGetters('AuthModule', ['getTeam', 'getUser'])
  },
  async mounted () {
    await this.fetchTeam()
    if (window.location.hash === '#instagram') {
      this.hideInstagramModal = false
    }
  },
  methods: {
    async fetchTeam () {
      if (!this.getTeam) return
      this.team = await FirebaseAPI.Teams.getTeamMembers()
    },
    scrollToInstagramConnect () {
      const el = document.getElementById('integrations-card')
      el.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>
<style scoped></style>
